import SuperRepository from '../superRepository'

export default new (class CallControllerRepository extends SuperRepository {
  baseUri() {
    return 'call_controller'
  }
  getRecordFile = async (id, params = {}) =>
    this.httpClient().getFile(`${this.baseUri()}/${id}/record_file`, params)
  getRecordFileByUrl = async (url = '') =>
    this.httpClient().getFile(`${this.baseUri()}/record_file_by_url?url=${url}`)
  report = async (data, params) =>
    this.httpClient().get(`${this.baseUri()}/report`, data, params)
})()
