import Vue from 'vue'
import Vuex from 'vuex'

import AuthModule from '@/store/modules/Auth.module'
import ApproveSpeedModule from '@/store/modules/ApproveSpeed.module'
import AverageCheckModule from '@/store/modules/AverageCheck.module'
import BlackPhoneModule from '@/store/modules/BlackPhone.module'
import BuybackDynamicsModule from '@/store/modules/BuybackDynamics.module'
import CallControllerModule from '@/store/modules/CallController.module'
import CallCentreModule from '@/store/modules/CallCentre.module'
import CallCenterPriceModule from '@/store/modules/CallCenterPrice.module'
import CallCenterPriceImportModule from '@/store/modules/CallCenterPriceImport.module'
import CallStatisticsModule from '@/store/modules/CallStatistics.module'
import CampaignModule from '@/store/modules/Campaign.module'
import CampaignQueueModule from '@/store/modules/CampaignQueue.module'
import CcStatisticsDashboardModule from '@/store/modules/CCStatisticsDashboard.module'
import CityImportModule from '@/store/modules/CityImport.module'
import CityModule from '@/store/modules/City.module'
import CountryModule from '@/store/modules/Country.module'
import CurrenciesModule from '@/store/modules/Currencies.module'
import DadataModule from '@/store/modules/Dadata.module'
import DashboardManagerModule from '@/store/modules/DashboardManager.module'
import DashboardOperatorModule from '@/store/modules/DashboardOperator.module'
import DeliveryCourierModule from '@/store/modules/DeliveryCourier.module'
import DeliveryExcludedRulesModule from '@/store/modules/DeliveryExcludedRules.module'
import DeliveryIntervalModule from '@/store/modules/DeliveryInterval.module'
import DeliveryPointsModule from '@/store/modules/DeliveyPoints.module'
import DeliveryPointsImportModule from '@/store/modules/DeliveryPointsImport.module'
import DeliveryPointsOrderModule from '@/store/modules/DeliveryPointsOrder.module'
import DeliveryPostModule from '@/store/modules/DeliveryPost.module'
import DeliveryServiceCityModule from '@/store/modules/DeliveryServiceCity.module'
import DeliveryServiceModule from '@/store/modules/DeliveryService.module'
import DeliveryServiceAnalyticModule from '@/store/modules/DeliveryServiceAnalytic.module'
import DeliveryTaxModule from '@/store/modules/DeliveryTax.module'
import DeliveryTaxImportModule from '@/store/modules/DeliveryTaxImport.module'
import DeliveryWarehouse1CModule from '@/store/modules/DeliveryWarehouse1C.module'
import DeliveryWarehouseModule from '@/store/modules/DeliveryWarehouse.module'
import DeliveryWarehouseStockModule from '@/store/modules/DeliveryWarehouseStock.module'
import DeliveryWeekendModule from '@/store/modules/DeliveryWeekend.module'
import DistributionCheckModule from '@/store/modules/DistributionCheck.module'
import DistrictImportModule from '@/store/modules/DistrictImport.module'
import DistrictModule from '@/store/modules/District.module'
import DostavimModule from '@/store/modules/Dostavim.module'
import FileModule from '@/store/modules/File.module'
import FinanceSettingsModule from '@/store/modules/FinanceSettings.module'
import FinancialReportModule from '@/store/modules/FinancialReport.module'
import FinancialReportOrdersModule from '@/store/modules/FinancialReportOrders.module'
import GradeHistoryModule from '@/store/modules/GradeHistory.module'
import KlnAssessmentModule from '@/store/modules/KlnAssessment.module'
import KlnOperatorAssessmentModule from '@/store/modules/KlnOperatorAssessment.module'
import KlnSectionModule from '@/store/modules/KlnSection.module'
import LandingModule from '@/store/modules/Landing.module'
import LanguageModule from '@/store/modules/Language.module'
import LegalPersonsStatsModule from '@/store/modules/LegalPersonsStats.module'
import LogAuthModule from '@/store/modules/LogAuth.module'
import LogisticPriceModule from '@/store/modules/LogisticPrice.module'
import LogisticPriceImportModule from '@/store/modules/LogisticPriceImport.module'
import LogOrderModule from '@/store/modules/LogOrder.module'
import LogWatchNumberModule from '@/store/modules/LogWatchNumber.module'
import MetashipAccountsModule from '@/store/modules/MetashipAccounts.module'
import MetashipAccountModule from '@/store/modules/MetashipAccount.module'
import ModalModule from '@/store/modules/Modal.module'
import NotificationModule from '@/store/modules/Notification.module'
import OfferGroupsModule from '@/store/modules/OfferGroups.module'
import OfferModule from '@/store/modules/Offer.module'
import OfferNichesModule from '@/store/modules/OfferNiches.module'
import OfferNormsModule from '@/store/modules/OfferNorms.module'
import OfferRatingModule from '@/store/modules/OfferRating.module'
import OfferSalesModelModule from '@/store/modules/OfferSalesModel.module'
import OktellModule from '@/store/modules/Oktell.module'
import OperatorsGradeModule from '@/store/modules/OperatorsGrade.module'
import OrderFraudModule from '@/store/modules/OrderFraud.module'
import OrderImportModule from '@/store/modules/OrderImport.module'
import OrderLeadAssociating from '@/store/modules/OrderLeadAssociating.module'
import OrderLogisticsModule from '@/store/modules/OrderLogistics.module'
import OrderModule from '@/store/modules/Order.module'
import OrderNoticeDepartmentModule from '@/store/modules/OrderNoticeDepartment.module'
import OrderOperatorModule from '@/store/modules/OrderOperator.module'
import OrderRansom from '@/store/modules/OrderRansom.module'
import OrderTagModule from '@/store/modules/OrderTag.module'
import OrderTrashCheckedModule from '@/store/modules/OrderTrashChecked.module'
import OrderTrashDisputeModule from '@/store/modules/OrderTrashDispute.module'
import OrderTrashModule from '@/store/modules/OrderTrash.module'
import OrderReportModule from '@/store/modules/OrderReport.module'
import OrderWebAnalyticaModule from '@/store/modules/OrderWebAnalytica.module'
import OrganizationModule from '@/store/modules/Organization.module'
import PaymentHoldModule from '@/store/modules/PaymentHold.module'
import PaymentProfitModule from '@/store/modules/PaymentProfit.module'
import PenaltyModule from '@/store/modules/Penalty.module'
import PermissionModule from '@/store/modules/Permission.module'
import PermissionControlModule from '@/store/modules/PermissionControl.module'
import PhoneCodeModule from '@/store/modules/PhoneCode.module'
import PrepaymentModule from '@/store/modules/Prepayment.module'
import PrepaymentTwoModule from '@/store/modules/PrepaymentTwo.module'
import PrepaymentTeamleadModule from '@/store/modules/PrepaymentTeamlead.module'
import PrepaymentTeamleadTwoModule from '@/store/modules/PrepaymentTeamleadTwo.module'
import ProductGiftModule from '@/store/modules/ProductGift.module'
import ProductModule from '@/store/modules/Product.module'
import ProductSetsModule from '@/store/modules/ProductSets.module'
import ProductTypeModule from '@/store/modules/ProductType.module'
import ProfileModule from '@/store/modules/Profile.module'
import QuestionnaireAnswerModule from '@/store/modules/QuestionnaireAnswer.module'
import QuestionnairesModule from '@/store/modules/Questionnaires.module'
import QuestionnairesOrderModule from '@/store/modules/QuestionnairesOrder.module'
import ReconfirmationStatisticModule from '@/store/modules/ReconfirmationStatistic.module'
import RejectionStatisticsModule from '@/store/modules/RejectionStatistics.module'
import RegionImportModule from '@/store/modules/RegionImport.module'
import RegionModule from '@/store/modules/Region.module'
import RepeatedConfirmModule from '@/store/modules/RepeatedConfirm.module'
import SalaryBonusImportModule from '@/store/modules/SalaryBonusImport.module'
import SalaryBonusModule from '@/store/modules/SalaryBonus.module'
import SalesModelModule from '@/store/modules/SalesModel.module'
import SalesScriptModule from '@/store/modules/SalesScript.module'
import SalesScriptTemplateModule from '@/store/modules/SalesScriptTemplate.module'
import ScriptMacrosModule from '@/store/modules/ScriptMacros.module'
import ScriptStylesModule from '@/store/modules/ScriptStyles.module'
import SettingModule from '@/store/modules/Setting.module'
import SmsCampaignModule from '@/store/modules/SmsCampaign.module'
import SmsModule from '@/store/modules/Sms.module'
import SmsSenderModule from '@/store/modules/SmsSender.module'
import SmsTemplateModule from '@/store/modules/SmsTemplate.module'
import StatusReasonModule from '@/store/modules/StatusReason.module'
import StopWordModule from '@/store/modules/StopWord.module'
import TicketsModule from '@/store/modules/Tickets.module'
import UserDayScheduleModule from '@/store/modules/UserDaySchedule.module'
import UserGroupsModule from '@/store/modules/UserGroups.module'
import UserGroupTypeModule from '@/store/modules/UserGroupType.module'
import UserNotificationsModule from '@/store/modules/UserNotifications.module'
import UserPenaltyModule from '@/store/modules/UserPenalty.module'
import UserRoleModule from '@/store/modules/UserRole.module'
import UserSalaryModule from '@/store/modules/UserSalary.module'
import UsersModule from '@/store/modules/Users.module'
import WorkTimeModule from '@/store/modules/WorkTime.module'
import LogAccountsModule from '@/store/modules/LogAccounts.module'
import LogAbnormalModule from '@/store/modules/LogAbnormal.module'
import UserScheduleModule from '@/store/modules/UserSchedule.module'
import TicketTypesModule from '@/store/modules/TicketTypes.module'
import UserDayOperatorScheduleModule from '@/store/modules/UserDayOperatorSchedule.module'
import CountrySmsSettingModule from '@/store/modules/CountrySmsSetting.module'
import SmsOperatorRelationModule from '@/store/modules/smsOperatorRelation.module'
import SmsChainModule from '@/store/modules/SmsChain.module'
import MessageChainUnitModule from '@/store/modules/MessageChainUnit.module'
import MyTicketsModule from '@/store/modules/MyTickets.module'
import OrderDeliveryStatusLogModule from '@/store/modules/OrderDeliveryStatusLog.module'
import IpAddressModule from '@/store/modules/IpAddress.module'
// __generator_imports__
import ReportTemplateModule from '@/store/modules/ReportTemplate.module'
import InvoiceTranslationsModule from '@/store/modules/InvoiceTranslations.module'
import InvoiceConstructorModule from '@/store/modules/InvoiceConstructor.module'
import OperatorOrderTimeReportModule from '@/store/modules/OperatorOrderTimeReport.module'
import CurrencyRateModule from '@/store/modules/CurrencyRate.module'
import CallCenterWebsModule from '@/store/modules/CallCenterWebs.module'
import OperatorStatusesModule from '@/store/modules/OperatorStatuses.module'
import OrderSubStatusesModule from '@/store/modules/OrderSubStatuses.module'
import WriteOffHistoryModule from '@/store/modules/WriteOffHistory.module'
import PostalCodesModule from '@/store/modules/PostalCodes.module'
import PostalCodesImportModule from '@/store/modules/PostalCodesImport.module'
import OfferSetsModule from '@/store/modules/OfferSets.module'
import DeliveryWarehouseImportModule from '@/store/modules/DeliveryWarehouseImport.module'
import RelatedStatusOfferModule from '@/store/modules/RelatedStatusOffer.module'
import UserFilterModule from '@/store/modules/UserFilter.module'
import CallMessagesStatisticModule from '@/store/modules/CallMessagesStatistic.module'
import OperatorStatisticModule from '@/store/modules/OperatorStatistic.module'
import OperatorEfficiencyModule from '@/store/modules/OperatorEfficiency.module'
import CallAnalyticsModule from '@/store/modules/CallAnalytics.module'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    AuthModule,
    ApproveSpeedModule,
    BlackPhoneModule,
    CallControllerModule,
    CallMessagesStatisticModule,
    CampaignModule,
    CampaignQueueModule,
    CityImportModule,
    CityModule,
    CountryModule,
    CurrenciesModule,
    DadataModule,
    DashboardManagerModule,
    DashboardOperatorModule,
    DeliveryCourierModule,
    DeliveryExcludedRulesModule,
    DeliveryIntervalModule,
    DeliveryPointsModule,
    DeliveryPointsOrderModule,
    DeliveryPostModule,
    DeliveryServiceCityModule,
    DeliveryServiceModule,
    DeliveryWarehouse1CModule,
    DeliveryWarehouseModule,
    DeliveryWarehouseStockModule,
    DeliveryWeekendModule,
    DistrictImportModule,
    DistrictModule,
    FileModule,
    FinanceSettingsModule,
    KlnAssessmentModule,
    KlnOperatorAssessmentModule,
    KlnSectionModule,
    LandingModule,
    LanguageModule,
    LegalPersonsStatsModule,
    LogAuthModule,
    LogOrderModule,
    LogisticPriceModule,
    LogisticPriceImportModule,
    ModalModule,
    MetashipAccountsModule,
    MetashipAccountModule,
    NotificationModule,
    OfferGroupsModule,
    OfferModule,
    OfferNichesModule,
    OfferNormsModule,
    OfferRatingModule,
    OfferSalesModelModule,
    OperatorsGradeModule,
    OrderFraudModule,
    OrderImportModule,
    OrderLeadAssociating,
    OrderLogisticsModule,
    OrderModule,
    OrderOperatorModule,
    OrderRansom,
    OrderTagModule,
    OrderTrashCheckedModule,
    OrderTrashDisputeModule,
    OrderTrashModule,
    OrderWebAnalyticaModule,
    OrganizationModule,
    PaymentHoldModule,
    PaymentProfitModule,
    PermissionModule,
    PhoneCodeModule,
    PrepaymentModule,
    PrepaymentTwoModule,
    PrepaymentTeamleadModule,
    PrepaymentTeamleadTwoModule,
    ProductGiftModule,
    ProductModule,
    ProductSetsModule,
    ProductTypeModule,
    ProfileModule,
    QuestionnaireAnswerModule,
    QuestionnairesModule,
    QuestionnairesOrderModule,
    RegionImportModule,
    RegionModule,
    RepeatedConfirmModule,
    SalaryBonusImportModule,
    SalaryBonusModule,
    SalesModelModule,
    SalesScriptModule,
    SalesScriptTemplateModule,
    ScriptMacrosModule,
    ScriptStylesModule,
    SettingModule,
    SmsCampaignModule,
    SmsModule,
    SmsSenderModule,
    SmsTemplateModule,
    StatusReasonModule,
    StopWordModule,
    TicketsModule,
    MyTicketsModule,
    TicketTypesModule,
    UserGroupsModule,
    UserGroupTypeModule,
    UserNotificationsModule,
    UserPenaltyModule,
    UserRoleModule,
    UsersModule,
    WorkTimeModule,
    AverageCheckModule,
    DeliveryServiceAnalyticModule,
    GradeHistoryModule,
    OrderReportModule,
    DeliveryPointsImportModule,
    DistributionCheckModule,
    UserSalaryModule,
    BuybackDynamicsModule,
    RejectionStatisticsModule,
    CallCentreModule,
    OperatorStatisticModule,
    ReconfirmationStatisticModule,
    CallStatisticsModule,
    DeliveryTaxModule,
    CallCenterPriceModule,
    CallCenterPriceImportModule,
    DeliveryTaxImportModule,
    FinancialReportModule,
    FinancialReportOrdersModule,
    PermissionControlModule,
    DostavimModule,
    OktellModule,
    PenaltyModule,
    OrderNoticeDepartmentModule,
    LogWatchNumberModule,
    LogAccountsModule,
    LogAbnormalModule,
    UserDayScheduleModule,
    UserScheduleModule,
    UserDayOperatorScheduleModule,
    CcStatisticsDashboardModule,
    CountrySmsSettingModule,
    SmsOperatorRelationModule,
    SmsChainModule,
    MessageChainUnitModule,
    IpAddressModule,
    OrderDeliveryStatusLogModule,
    // __imported_entities__  
    ReportTemplateModule,
    InvoiceTranslationsModule,
    InvoiceConstructorModule,
    OperatorOrderTimeReportModule,
    CurrencyRateModule,
    CallCenterWebsModule,
    OperatorStatusesModule,
    OrderSubStatusesModule,
    WriteOffHistoryModule,
    PostalCodesModule,
    PostalCodesImportModule,
    OfferSetsModule,
    DeliveryWarehouseImportModule,
    RelatedStatusOfferModule,
    UserFilterModule,
    OperatorEfficiencyModule,
    CallAnalyticsModule,
  },
})
